export const customerLevelsDict = [
  { code: 'INTERNATIONAL', title: 'Международный' },
  { code: 'FEDERAL', title: 'Федеральный' },
  { code: 'REGIONAL', title: 'Региональный' },
  { code: 'MUNICIPAL', title: 'Муниципальный' },
  { code: 'CORPORATIONAL', title: 'Корпоративный' },
];

//'code' property is added by 'map' in the end of the array initialization

export const regionsDict = [
  { id: 1, title: 'Республика Адыгея (Адыгея)' },
  {
    id: 2,
    title: 'Республика Алтай',
  },
  { id: 3, title: 'Республика Башкортостан' },
  { id: 4, title: 'Республика Бурятия' },
  {
    id: 5,
    title: 'Республика Дагестан',
  },
  { id: 6, title: 'Республика Ингушетия' },
  { id: 7, title: 'Кабардино-Балкарская Республика' },
  {
    id: 8,
    title: 'Республика Калмыкия',
  },
  { id: 9, title: 'Карачаево-Черкесская Республика' },
  { id: 10, title: 'Республика Карелия' },
  {
    id: 11,
    title: 'Республика Коми',
  },
  { id: 12, title: 'Республика Крым' },
  { id: 13, title: 'Республика Марий Эл' },
  {
    id: 14,
    title: 'Республика Мордовия',
  },
  { id: 15, title: 'Республика Саха (Якутия)' },
  {
    id: 16,
    title: 'Республика Северная Осетия – Алания',
  },
  { id: 17, title: 'Республика Татарстан (Татарстан)' },
  { id: 18, title: 'Республика Тыва' },
  {
    id: 19,
    title: 'Удмуртская Республика',
  },
  { id: 20, title: 'Республика Хакасия' },
  { id: 21, title: 'Чеченская Республика' },
  {
    id: 22,
    title: 'Чувашская Республика – Чувашия',
  },
  { id: 23, title: 'Алтайский край' },
  { id: 24, title: 'Забайкальский край' },
  {
    id: 25,
    title: 'Камчатский край',
  },
  { id: 26, title: 'Краснодарский край' },
  { id: 27, title: 'Красноярский край' },
  {
    id: 28,
    title: 'Пермский край',
  },
  { id: 29, title: 'Приморский край' },
  { id: 30, title: 'Ставропольский край' },
  {
    id: 31,
    title: 'Хабаровский край',
  },
  { id: 32, title: 'Амурская область' },
  { id: 33, title: 'Архангельская область' },
  {
    id: 34,
    title: 'Астраханская область',
  },
  { id: 35, title: 'Белгородская область' },
  { id: 36, title: 'Брянская область' },
  {
    id: 37,
    title: 'Владимирская область',
  },
  { id: 38, title: 'Волгоградская область' },
  { id: 39, title: 'Вологодская область' },
  {
    id: 40,
    title: 'Воронежская область',
  },
  { id: 41, title: 'Ивановская область' },
  { id: 42, title: 'Иркутская область' },
  {
    id: 43,
    title: 'Калининградская область',
  },
  { id: 44, title: 'Калужская область' },
  { id: 45, title: 'Кемеровская область' },
  {
    id: 46,
    title: 'Кировская область',
  },
  { id: 47, title: 'Костромская область' },
  { id: 48, title: 'Курганская область' },
  {
    id: 49,
    title: 'Курская область',
  },
  { id: 50, title: 'Ленинградская область' },
  { id: 51, title: 'Липецкая область' },
  {
    id: 52,
    title: 'Магаданская область',
  },
  { id: 53, title: 'Московская область' },
  { id: 54, title: 'Мурманская область' },
  {
    id: 55,
    title: 'Нижегородская область',
  },
  { id: 56, title: 'Новгородская область' },
  { id: 57, title: 'Новосибирская область' },
  {
    id: 58,
    title: 'Омская область',
  },
  { id: 59, title: 'Оренбургская область' },
  { id: 60, title: 'Орловская область' },
  {
    id: 61,
    title: 'Пензенская область',
  },
  { id: 62, title: 'Псковская область' },
  { id: 63, title: 'Ростовская область' },
  {
    id: 64,
    title: 'Рязанская область',
  },
  { id: 65, title: 'Самарская область' },
  { id: 66, title: 'Саратовская область' },
  {
    id: 67,
    title: 'Сахалинская область',
  },
  { id: 68, title: 'Свердловская область' },
  { id: 69, title: 'Смоленская область' },
  {
    id: 70,
    title: 'Тамбовская область',
  },
  { id: 71, title: 'Тверская область' },
  { id: 72, title: 'Томская область' },
  {
    id: 73,
    title: 'Тульская область',
  },
  { id: 74, title: 'Тюменская область' },
  { id: 75, title: 'Ульяновская область' },
  {
    id: 76,
    title: 'Челябинская область',
  },
  { id: 77, title: 'Ярославская область' },
  { id: 78, title: 'Город Москва' },
  {
    id: 79,
    title: 'Город Санкт-Петербург',
  },
  { id: 80, title: 'Город Севастополь' },
  { id: 81, title: 'Еврейская автономная область' },
  {
    id: 82,
    title: 'Ненецкий автономный округ',
  },
  { id: 83, title: 'Ханты-Мансийский автономный округ – Югра' },
  {
    id: 84,
    title: 'Чукотский автономный округ',
  },
  { id: 85, title: 'Ямало-Ненецкий автономный округ' },
  { id: 86, title: 'Байконур' },
].map((e) => {
  return { id: e.id, title: e.title, code: '' + e.id, supId: e.id };
});

//'code' property is added by 'map' in the end of the array initialization
export const regionsDict223 = [
  { id: 1, title: 'Республика Адыгея', supId: 1 },
  { id: 2, title: 'Республика Алтай', supId: 2 },
  { id: 3, title: 'Республика Башкортостан', supId: 3 },
  { id: 4, title: 'Республика Бурятия', supId: 4 },
  { id: 5, title: 'Республика Дагестан', supId: 5 },
  { id: 6, title: 'Республика Ингушетия', supId: 6 },
  { id: 7, title: 'Кабардино-Балкарская Республика', supId: 7 },
  { id: 8, title: 'Республика Калмыкия', supId: 8 },
  { id: 9, title: 'Карачаево-Черкесская Республика', supId: 9 },
  { id: 10, title: 'Республика Карелия', supId: 10 },
  { id: 11, title: 'Республика Коми', supId: 11 },
  { id: 12, title: 'Республика Крым', supId: 12 },
  { id: 13, title: 'Республика Марий Эл', supId: 13 },
  { id: 14, title: 'Республика Мордовия', supId: 14 },
  { id: 15, title: 'Республика Саха (Якутия)', supId: 15 },
  { id: 16, title: 'Республика Северная Осетия – Алания', supId: 16 },
  { id: 17, title: 'Республика Татарстан (Татарстан)', supId: 17 },
  { id: 18, title: 'Республика Тыва', supId: 18 },
  { id: 19, title: 'Удмуртская Республика', supId: 19 },
  { id: 20, title: 'Республика Хакасия', supId: 20 },
  { id: 21, title: 'Чеченская Республика', supId: 21 },
  { id: 22, title: 'Чувашская Республика – Чувашия', supId: 22 },
  { id: 23, title: 'Алтайский край', supId: 23 },
  {
    id: 24,
    title: 'Агинский-Бурятский автономный окгруг (Забайкальский край)',
    supId: 24,
  },
  { id: 25, title: 'Забайкальский край', supId: 24 },
  { id: 26, title: 'Камчатский край', supId: 25 },
  { id: 27, title: 'Краснодарский край', supId: 26 },
  { id: 28, title: 'Красноярский край', supId: 27 },
  { id: 29, title: 'Пермский край', supId: 28 },
  { id: 30, title: 'Приморский край', supId: 29 },
  { id: 31, title: 'Ставропольский край', supId: 30 },
  { id: 32, title: 'Хабаровский край', supId: 31 },
  { id: 33, title: 'Амурская область', supId: 32 },
  { id: 34, title: 'Архангельская область', supId: 33 },
  { id: 35, title: 'Астраханская область', supId: 34 },
  { id: 36, title: 'Белгородская область', supId: 35 },
  { id: 37, title: 'Брянская область', supId: 36 },
  { id: 38, title: 'Владимирская область', supId: 37 },
  { id: 39, title: 'Волгоградская область', supId: 38 },
  { id: 40, title: 'Вологодская область', supId: 39 },
  { id: 41, title: 'Воронежская область', supId: 40 },
  { id: 42, title: 'Ивановская область', supId: 41 },
  { id: 43, title: 'Иркутская область', supId: 42 },
  {
    id: 44,
    title: 'Усть-Ордынский Бурятский округ (Иркутская область)',
    supId: 42,
  },
  { id: 45, title: 'Калининградская область', supId: 43 },
  { id: 46, title: 'Калужская область', supId: 44 },
  { id: 47, title: 'Кемеровская область', supId: 45 },
  { id: 48, title: 'Кировская область', supId: 46 },
  { id: 49, title: 'Костромская область', supId: 47 },
  { id: 50, title: 'Курганская область', supId: 48 },
  { id: 51, title: 'Курская область', supId: 49 },
  { id: 52, title: 'Ленинградская область', supId: 50 },
  { id: 53, title: 'Липецкая область', supId: 51 },
  { id: 54, title: 'Магаданская область', supId: 52 },
  { id: 55, title: 'Московская область', supId: 53 },
  { id: 56, title: 'Мурманская область', supId: 54 },
  { id: 57, title: 'Нижегородская область', supId: 55 },
  { id: 58, title: 'Новгородская область', supId: 56 },
  { id: 59, title: 'Новосибирская область', supId: 57 },
  { id: 60, title: 'Омская область', supId: 58 },
  { id: 61, title: 'Оренбургская область', supId: 59 },
  { id: 62, title: 'Орловская область', supId: 60 },
  { id: 63, title: 'Пензенская область', supId: 61 },
  { id: 64, title: 'Псковская область', supId: 62 },
  { id: 65, title: 'Ростовская область', supId: 63 },
  { id: 66, title: 'Рязанская область', supId: 64 },
  { id: 67, title: 'Самарская область', supId: 65 },
  { id: 68, title: 'Саратовская область', supId: 66 },
  { id: 69, title: 'Сахалинская область', supId: 67 },
  { id: 70, title: 'Свердловская область', supId: 68 },
  { id: 71, title: 'Смоленская область', supId: 69 },
  { id: 72, title: 'Тамбовская область', supId: 70 },
  { id: 73, title: 'Тверская область', supId: 71 },
  { id: 74, title: 'Томская область', supId: 72 },
  { id: 75, title: 'Тульская область', supId: 73 },
  { id: 76, title: 'Тюменская область', supId: 74 },
  { id: 77, title: 'Ульяновская область', supId: 75 },
  { id: 78, title: 'Челябинская область', supId: 76 },
  { id: 79, title: 'Ярославская область', supId: 77 },
  { id: 80, title: 'Город Москва', supId: 78 },
  { id: 81, title: 'Город Санкт-Петербург', supId: 79 },
  { id: 82, title: 'Город Севастополь', supId: 80 },
  { id: 83, title: 'Еврейская автономная область', supId: 81 },
  { id: 84, title: 'Ненецкий автономный округ', supId: 82 },
  { id: 85, title: 'Ханты-Мансийский автономный округ – Югра', supId: 83 },
  { id: 86, title: 'Чукотский автономный округ', supId: 84 },
  { id: 87, title: 'Ямало-Ненецкий автономный округ', supId: 85 },
  { id: 88, title: 'Байконур', supId: 86 },
].map((e) => {
  return { id: e.id, title: e.title, code: '' + e.id, supId: e.supId };
});

export const legalEntityTypeDict = [
  { code: 'ORG', title: 'Организация' }, // (без zakupki.gov.ru)
  { code: 'TCST', title: 'Заказчик 44-ФЗ или 223-ФЗ' },
  { code: 'IP', title: 'ИП' },
  { code: 'FL', title: 'Физическое лицо' },
];

export const clientTypeDict = [
  { code: 'ADMPRES', title: 'Администрация Президента РФ' },
  { code: 'BANKS', title: 'Банки и финансовые организации' },
  { code: 'BUSINESS', title: 'Бизнес-структуры' },
  { code: 'INTERN', title: 'Международные заказчики и фонды' },
  { code: 'NII', title: 'НИИ и ВУЗы' },
  { code: 'MSP', title: 'Объединения и структуры поддержки МСП' },
  { code: 'ROIV', title: 'РОИВ, ОМСУ и их учреждения' },
  { code: 'FEDSOBR', title: 'Федеральное собрание РФ' },
  { code: 'FOIV', title: 'ФОИВ и их учреждения' },
];

export const problemCustomerTypeDict = [
  {
    code: 'DISPUTE',
    title:
      'Заказчики, с которыми за последние 5 лет были судебные и внесудебные споры',
  },
  {
    code: 'AFFILIATE',
    title:
      'Заказчики, по которым есть ограничения в виде конфликта интересов: заказчиком является родственник сотрудника группы компаний; бывший сотрудник группы компаний; иное аффилированное лицо',
  },
];

export const customerTimeZoneDict = [
  {
    code: 'UTC2',
    title: 'МСК−1: Калининград',
    jsName: 'Europe/Kaliningrad',
    offset: '+02',
  },
  {
    code: 'UTC3',
    title: 'МСК: Москва',
    jsName: 'Europe/Moscow',
    offset: '+03',
  },
  {
    code: 'UTC4',
    title: 'МСК+1: Самара',
    jsName: 'Europe/Samara',
    offset: '+04',
  },
  {
    code: 'UTC5',
    title: 'МСК+2: Екатеринбург',
    jsName: 'Asia/Yekaterinburg',
    offset: '+05',
  },
  { code: 'UTC6', title: 'МСК+3: Омск', jsName: 'Asia/Omsk', offset: '+06' },
  {
    code: 'UTC7',
    title: 'МСК+4: Красноярск',
    jsName: 'Asia/Krasnoyarsk',
    offset: '+07',
  },
  {
    code: 'UTC8',
    title: 'МСК+5: Иркутск',
    jsName: 'Asia/Irkutsk',
    offset: '+08',
  },
  {
    code: 'UTC9',
    title: 'МСК+6: Якутск',
    jsName: 'Asia/Yakutsk',
    offset: '+09',
  },
  {
    code: 'UTC10',
    title: 'МСК+7: Владивосток',
    jsName: 'Asia/Vladivostok',
    offset: '+10',
  },
  {
    code: 'UTC11',
    title: 'МСК+8: Магадан',
    jsName: 'Asia/Magadan',
    offset: '+11',
  },
  {
    code: 'UTC12',
    title: 'МСК+9: Камчатка',
    jsName: 'Asia/Kamchatka',
    offset: '+12',
  },
];

export const jobTypeDict = [
  { code: 'FULL', title: 'Полная' },
  { code: 'PART', title: 'Частичная' },
];

export const purchaseProceduresDict = [
  { code: 'EA44', title: 'Электронный аукцион', law: 'FZ44' },
  { code: 'OK504', title: 'Открытый конкурс в электронной форме', law: 'FZ44' },
  {
    code: 'COM223',
    title: 'Закупка по 223-ФЗ (тип определяется организацией)',
    law: 'FZ223',
  },
  {
    code: 'ZKP504',
    title: 'Запрос котировок в электронной форме',
    law: 'FZ44',
  },
  { code: 'EAP2020', title: 'Электронный аукцион', law: 'FZ44' },
];

export const riskAssessmentDict = [
  { code: 'HIGH', title: 'Высокорисковая заявка' },
  { code: 'MEDIUM', title: 'Рисковая заявка' },
  { code: 'LOW', title: 'Безрисковая заявка' },
];

export const applicationStatusDict = [
  { code: 'NOTSTARTED', title: 'Не начата' },
  { code: 'INPROGRESS', title: 'Подготовка' },
  { code: 'SENT', title: 'Отправлена (подана)' },
  { code: 'WIN', title: 'Победа' },
  { code: 'LOSE', title: 'Проигрыш' },
];

export const contractStatusDict = [
  { code: 'DRAFT', title: 'Проект договора' },
  { code: 'PROGRESS', title: 'Исполнение' },
  { code: 'PAUSE', title: 'Приостановлен' },
  { code: 'WARRANTY', title: 'Закрыт (гарантия)' },
  { code: 'VOID', title: 'Аннулирован' },
  { code: 'FINISH', title: 'Закрыт' },
];

export const contractForSiteDict = [
  { code: 'true', title: 'Да' },
  { code: 'false', title: 'Нет' }
];

export const purchaseLawDict = [
  { code: 'FZ44', title: '44-ФЗ' },
  { code: 'FZ223', title: '223-ФЗ' },
];

export const guaranteeTypeDict = [
  { code: 'ACCOUNT', title: 'Внесение на спецсчёт' },
  { code: 'BANK', title: 'Банковская гарантия' },
];

export const supMarketDict = [
  { code: 'ANTICOR', title: '*Антикоррупционная политика' },
  { code: 'INNOV', title: '*Инновационная политика' },
  {
    code: 'MSP',
    title: '*Исследования в области малого и среднего предпринимательства',
  },
  { code: 'IKT', title: '*Консалтинг в области ИКТ' },
  {
    code: 'OPTIM',
    title: '*Оптимизация государственных и муниципальных полномочий',
  },
  { code: 'ORV', title: '*Оценка регулирующего воздействия' },
  { code: 'INVEST', title: '*Привлечение инвестиций' },
  { code: 'CONC', title: '*Развитие конкуренции' },
  { code: 'EST', title: '*Развитие рынка земли и недвижимости' },
  { code: 'TEHREG', title: '*Техническое регулирование' },
  { code: 'DEV', title: 'Внедрение ИКТ' },
  { code: 'GRAD', title: 'Градостроительная политика' },
  { code: 'EXP', title: 'Межотраслевая экспертиза' },
  { code: 'PURC', title: 'Оптимизация закупочной деятельности государства' },
  { code: 'POL', title: 'Оценка политических настроений граждан' },
  { code: 'IND', title: 'Промышленная политика' },
  { code: 'COMM', title: 'Развитие жилищно-коммунального хозяйства' },
  {
    code: 'STAFF',
    title: 'Развитие системы государственной и гражданской службы',
  },
  { code: 'TRANSP', title: 'Развитие транспортной системы и дорожной сети' },
  { code: 'TOUR', title: 'Развитие туристической индустрии' },
  { code: 'ENERG', title: 'Регулирование энергетики' },
  {
    code: 'FINANC',
    title: 'Совершенствование государственных и муниципальных финансов',
  },
  { code: 'SOC', title: 'Социальная политика' },
  { code: 'ECON', title: 'Социально-экономическое развитие регионов' },
  { code: 'PRIV', title: 'Частный консалтинг' },
  { code: 'OTH', title: 'Другое' },
];

export const technologiesDict = [
  { code: 'STRATPROJ', title: 'Стратегическое проектирование' },
  {
    code: 'CONCEPT',
    title: 'Разработка стратегических, концептуальных и программных документов',
  },
  {
    code: 'RULECHG',
    title: 'Подготовка предложений по изменению регулирования',
  },
  { code: 'NPAPROJECT', title: 'Нормативно-правовое проектирование' },
  { code: 'NPADEV', title: 'Разработка нормативных правовых актов' },
  { code: 'ADMREG', title: 'Разработка административных регламентов' },
  { code: 'TEHREG', title: 'Разработка технических регламентов' },
  { code: 'METDOC', title: 'Разработка методических документов' },
  { code: 'NPAAN', title: 'Анализ и экспертиза нормативных актов' },
  { code: 'ARBITR', title: 'Анализ арбитражной практики' },
  { code: 'SOCIO', title: 'Социологические исследования' },
  { code: 'INTW', title: 'Проведение личного формализованного интервью' },
  { code: 'FLATSOC', title: ' Проведение квартирного опроса' },
  { code: 'TELSOC', title: 'Проведение интервью по телефону' },
  { code: 'FOC', title: 'Проведение фокус-группы' },
  { code: 'CTRLPURC', title: 'Проведения контрольной закупки' },
  { code: 'PASS', title: 'Обследование пассажиропотоков' },
  { code: 'DAYPHOTO', title: 'Обследование "Фотография рабочего дня"' },
  { code: 'MONITOR', title: 'Проведение мониторингов' },
  { code: 'COMMONITOR', title: 'Проведение мониторингов' },
  { code: 'SECTORAN', title: 'Отраслевой анализ' },
  { code: 'FORCST', title: 'Прогнозирование' },
  { code: 'EXTRAP', title: 'Подготовка прогнозов (методом экстраполяции)' },
  {
    code: 'TENDC',
    title:
      'Разработка прогнозов с помощью глубокого анализа факторов и тенденций',
  },
  { code: 'FORSITE', title: 'Использование методов форсайта' },
  { code: 'BESTPRAC', title: 'Изучение лучшей практики' },
  { code: 'FRGNEXP', title: 'Анализ зарубежного опыта' },
  { code: 'REGEXP', title: 'Анализ регионального опыта' },
  { code: 'VEDEXP', title: 'Анализ ведомственного опыта' },
  { code: 'ORGPROJ', title: 'Организационное проектирование' },
  { code: 'ADMPROC', title: 'Описание административных процессов' },
  {
    code: 'FUNCAN',
    title: 'Горизонтальный и вертикальный функциональный анализ',
  },
  { code: 'PROCOPT', title: 'Оптимизация административных процедур' },
  { code: 'FUNCREEST', title: 'Подготовка реестров функций и услуг' },
  {
    code: 'ORGCREAT',
    title:
      'Сопровождение создания организаций  (учреждений, фондов, МФЦ, органов и т.д.)',
  },
  {
    code: 'ROTMECH',
    title: 'Разработка механизмов плановой и внеплановой ротации',
  },
  { code: 'STAFFOPT', title: 'Оптимизация штатной численности' },
  { code: 'EVOPT', title: 'Организация мероприятий' },
  {
    code: 'CONORG',
    title: 'Организация конференций, круглых столов, семинаров и т.д.',
  },
  { code: 'EVA', title: 'Оценка (Evaluation)' },
  { code: 'PROGEVA', title: 'Оценка политик и программ, включая ОРВ (ОФВ)' },
  { code: 'EFFEVA', title: 'Оценка деятельности органов власти' },
  {
    code: 'SUBEVA',
    title: 'Оценка деятельности подведомственных предприятий и учреждений',
  },
  {
    code: 'KPI',
    title: 'Разработка показателей результативности деятельности (KPI)',
  },
  { code: 'RATE', title: 'Подготовка рейтингов' },
  {
    code: 'APPEVA',
    title: 'Экспертиза заявок от коммерческих организаций в органы власти',
  },
  { code: 'ICONS', title: 'ИТ-консалтинг' },
  { code: 'ISEVA', title: 'Обследование информационных систем' },
  { code: 'EGOVT', title: 'Экспертиза решений для электронного правительства' },
  {
    code: 'ISPROJ',
    title: 'Проектирование информационных систем, порталов, баз данных и т.д.',
  },
  { code: 'VIRTZ', title: 'Визуализация деятельности власти' },
  { code: 'BPDESC', title: 'Описание бизнес-процессов' },
  {
    code: 'INFMODEL',
    title: 'Построение процессных и информационных моделей органов власти',
  },
  { code: 'PODEV', title: 'Разработка програмнного обеспечения' },
  {
    code: 'DBFILL',
    title: 'Наполнение информационных систем, порталов, баз данных и т.д.',
  },
  { code: 'DATA', title: 'Анализ данных' },
  { code: 'DATAENG', title: 'Инжиниринг данных' },
  { code: 'BIGDT', title: 'Анализ "больших данных"' },
  { code: 'EDU', title: 'Обучение публичных служащих' },
  {
    code: 'PRACT',
    title: 'Проведение практикумов, обучающих семинаров, тренингов',
  },
  { code: 'GAME', title: 'Игровое моделирование ситуаций' },
  { code: 'PUB', title: 'Подготовка справочных изданий, учебников' },
  { code: 'INVEST', title: 'Сопровождение инвестиционных проектов' },
  {
    code: 'INNOEXP',
    title: 'Экономическая и технологическая экспертиза инновационных проектов',
  },
  { code: 'BUSPLAN', title: 'Разработка бизнес-планов' },
  { code: 'FINMOD', title: 'Финансовое моделирование' },
  { code: 'FINFIND', title: 'Поиск инвесторов' },
  { code: 'FINBARG', title: 'Сопровождение инвестиционных сделок' },
];

export const projectStatusDict = [
  { code: 'NEGO', title: 'Переговоры' },
  { code: 'INPROGRESS', title: 'Исполнение' },
  { code: 'FINISH', title: 'Завершен' },
  { code: 'PAUSE', title: 'Приостановлен' },
  { code: 'CANCEL', title: 'Отменен' },
];

export const subWeightsPDict = [
  { code: 'HONR', title: 'Учет заслуг других партнеров', default: 10 },
  { code: 'MEET', title: 'Организация встречи', default: 10 },
  { code: 'IDEA', title: 'Идея проекта', default: 20 },
  { code: 'TASK', title: 'Разработка ТЗ', default: 20 },
  { code: 'CONC', title: 'Разработка КД, подготовка договора', default: 2 },
  { code: 'APPL', title: 'Подготовка заявки', default: 30 },
  { code: 'EXPA', title: 'Экспертиза заявок', default: 3 },
  { code: 'CNFL', title: 'Решение конфликтов', default: 5 },
];
export const ourOrgsAccountsDict = [
  {
    id: 3,
    code: 3,
    title: 'НИСИПП',
    shortName: 'НИСИПП',
    accounts: ['40817810099910004312', '40817810099910004315'],
  },
  {
    id: 15,
    code: 15,
    title: 'АНО ИКЦ "Бизнес-Тезаурус"',
    shortName: 'АНО ИКЦ "Бизнес-Тезаурус"',
    accounts: ['40817810099910004323', '40817810099910006712'],
  },
  {
    id: 16,
    code: 16,
    title: 'Эксперты ГК НИСИПП',
    shortName: 'Эксперты ГК НИСИПП',
    accounts: ['40817810099910009999', '40817810099910008989'],
  },
  {
    id: 191,
    code: 191,
    title: 'ООО "Л-Стар"',
    shortName: 'ООО "Л-Стар"',
    accounts: ['40817810099910001111', '40817810099910002222'],
  },
];

export const noContractProjectItems = [
  {
    title: 'Весь проект',
    code: 'fullProject'
  },
  {
    title: 'Часть проекта',
    code: 'partOfProject'
  }
]