import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { section: 'DESKTOP' },
  },
  {
    path: '/tenders',
    name: 'Tenders',
    component: () => import('../components/tenders/TendersComponent.vue'),
    meta: { section: 'TENDERS' },
  },
  {
    path: '/tender/:id',
    name: 'TenderInfo',
    component: () => import('../views/tender.vue'),
    meta: { section: 'TENDERS' },
    children: [
      {
        path: 'info',
        component: () => import('../components/tenders/TenderInfoTab.vue'),
        meta: { section: 'TENDERS' },
      },
      {
        path: 'files*',
        component: () => import('../components/files/FileStorage.vue'),
        meta: { section: 'TENDERS' },
      },
    ],
  },

  {
    path: '/tenderEdit/:id?',
    name: 'TenderEdit',
    component: () => import('../views/tenderEdit.vue'),
    meta: { section: 'TENDERS' },
  },
  {
    path: '/addTenders',
    name: 'AddTenders',
    component: () => import('../components/tenders/AddTenderForm.vue'),
    meta: { section: 'TENDERS' }
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () =>
      import('../components/applications/ApplicationsComponent.vue'),
    meta: { section: 'APPLICATIONS' },
  },
  {
    path: '/application',
    name: 'Application',
    component: () => import('../views/application.vue'),
    meta: { section: 'APPLICATION' },
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import('../components/contracts/ContractsComponent.vue'),
    meta: { section: 'CONTRACTS' },
  },
  {
    path: '/contract/:id',
    name: 'Contract',
    component: () => import('../views/contract.vue'),
    meta: { section: 'CONTRACTS' },
    children: [
      {
        path: 'info',
        component: () => import('../components/contracts/ContractInfoTab.vue'),
        meta: { section: 'CONTRACTS' },
      },
      {
        path: 'stages',
        component: () => import('../components/contracts/StagesTab.vue'),
        meta: { section: 'CONTRACTS' },
      },
      {
        path: 'files*',
        component: () => import('../components/files/FileStorage.vue'),
        meta: { section: 'CONTRACTS' },
      },
      {
        path: 'additional',
        component: () => import('../components/contracts/AdditionalTab.vue'),

        meta: {section: 'CONTRACTS'}
      },
      {
        path: 'wires',
        component: () => import('../components/contracts/ContractWiresTab.vue'),
        meta: {section: 'CONTRACTS'}
      }
    ]

  },
  {
    path: '/contractEdit/:id?',
    name: 'ContractEdit',
    component: () => import('../views/contractEdit.vue'),
    meta: { section: 'CONTRACTS' },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/projects.vue'),
    meta: { section: 'PROJECTS' },
  },
  {
    path: '/projectEdit/:id?',
    name: 'ProjectEdit',
    component: () => import('../views/projectEdit.vue'),
    meta: { section: 'PROJECTS' },
  },
  {
    path: '/newwiring/:id?',
    name: 'New Wiring',
    component: () => import('../views/newWiring.vue'),
    meta: { section: 'ENTRIES' },
  },
  {
    path: '/wiringEdit/:id?',
    name: 'WiringEdit',
    component: () => import('../views/wiringEdit.vue'),
    meta: { section: 'ENTRIES' },
  },
  {
    path: '/wiring/:id?',
    name: 'Wiring',
    component: () => import('../views/wiring.vue'),
    meta: { section: 'ENTRIES' },
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: () => import('../views/project.vue'),
    meta: { section: 'PROJECTS' },
  },
  {
    path: '/employees',
    name: 'Employees',
    component: () => import('../components/employees/EmployeesComponent.vue'),
    meta: { section: 'EMPLOYEES' },
  },
  {
    path: '/employee/:id',
    name: 'Employee',
    component: () => import('../views/employee.vue'),
    meta: { section: 'EMPLOYEES' },
    children: [
      {
        path: 'info',
        component: () => import('../components/employees/EmployeeInfoTab.vue'),
        meta: { section: 'EMPLOYEES' },
      },
      {
        path: 'files*',
        component: () => import('../components/files/FileStorage.vue'),
        meta: { section: 'EMPLOYEES' },
      },
    ],
  },
  {
    path: '/edit-employee/:id?',
    name: 'EditEmployee',
    component: () => import('../views/edit-employee.vue'),
    meta: { section: 'EMPLOYEES' },
  },
  {
    path: '/counterparties',
    name: 'Counterparties',
    component: () =>
      import('../components/counterparties/CounterpartiesComponent.vue'),
    meta: { section: 'ORGS' },
  },
  {
    path: '/counterparty/:id',
    name: 'Counterparty',
    component: () => import('../views/counterparty.vue'),
    meta: { section: 'ORGS' },
  },
  {
    path: '/counterpartyEdit/:id?',
    name: 'CounterpartyEdit',
    component: () => import('../views/counterpartyEdit.vue'),
    meta: { section: 'ORGS' },
  },
  {
    path: '/entries',
    name: 'Entries',
    component: () => import('../views/entries.vue'),
    meta: { section: 'ENTRIES' },
  },
  {
    path: '/entries1c',
    name: 'Entries1c',
    component: () => import('../views/entries1c.vue'),
    meta: { section: 'ENTRIES1C' },
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('../views/analytics.vue'),
    meta: { section: 'ANALYTICS' },
  },
  {
    path: '/knowledge',
    name: 'Knowledge',
    component: () => import('../views/knowledge.vue'),
    meta: { section: 'KNOWLEDGE' },
    children: [
      {
        path: 'files*',
        component: () => import('../components/files/FileStorage.vue'),
        meta: { section: 'KNOWLEDGE' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
