const api = {
    backendUrl: null,
    token: null,

    async get(path, querySpec, mode = "json") {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString()
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'GET', headers: this.token ? {
                    'Authorization': `Bearer ${this.token}`
                } : {}
            })
            
            if (req.status === 200) {
                let payload
                if (mode === "json") {
                    payload = await this.tryGetJson(req)
                } else if (mode === "blob") {
                    payload = await req.blob()
                }
                return {ok: true, payload: payload, status: req.status}
            } else {
                console.log(req)
                return {ok: false, payload: null, error: req.err, status: req.status}
            }
        } catch (e) {
            console.log(e)
            return {ok: false, error: e}
        }
    },

    async getFile(path, querySpec) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString()
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'GET', headers: this.token ? {
                    'Authorization': `Bearer ${this.token}`
                } : {}
            })
            if (req.ok) {
                let payload
                const header = req.headers.get('Content-Disposition');
                console.log(header)
                const parts = header.split(';');
                const fileName = parts[1].slice(11, -1)
                payload = await req.blob()
                return {ok: true, status: req.status, payload, fileName}
            } else {
                console.log(req)
                return {ok: false, payload: null, error: req.err, status: req.status}
            }
        } catch (e) {
            console.log(e)
            return {ok: false, error: e}
        }
    },

    async postJson(path, obj) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        try {
            let req = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            if (req.ok) {
                return {ok: true, payload: await this.tryGetJson(req)}
            } else {
                console.log(req)
                return {ok: false, payload: null, error: req.err ? req.err : await req.text()}
            }
        } catch (e) {
            console.log(e)
            return {ok: false, error: e}
        }
    },

    async postFormData(path, formData) {
        if (path[0] !== '/') {
            path = '/' + path
        }

        //На вход удобно также получать не форм дата, а объект
        if (!(formData instanceof FormData)) {
            let correctFormData = new FormData();

            for (let key in formData) {
                correctFormData.append(key, formData[key]);
            }
            formData = correctFormData
        }

        const url = new URL(this.backendUrl + path)
        let req = await fetch(url.toString(), {
            method: 'POST', headers: {
                'Authorization': `Bearer ${this.token}`,
            }, body: formData
        })
        const payload = await this.tryGetJson(req);
        if (req.ok) {
            return {ok: true, payload}
        } else {
            return {ok: false, payload: null, message: await req.text()}
        }
    },
    async postProgressFormData(path, formData, onUploadProgress) {
        if (path[0] !== '/') {
            path = '/' + path
        }

        //На вход удобно также получать не форм дата, а объект
        if (!(formData instanceof FormData)) {
            let correctFormData = new FormData();

            for (let key in formData) {
                correctFormData.append(key, formData[key]);
            }
            formData = correctFormData
        }
        const request = new Promise((resolve, reject) => {            
            const url = new URL(this.backendUrl + path)
            const xhr = new XMLHttpRequest();
            xhr.upload.onprogress = onUploadProgress;
            xhr.open('POST', url.toString(), true)
            xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
            xhr.send(formData)
            xhr.onreadystatechange = function() {
                if (xhr.readyState != 4) return;
              
                if (xhr.status == 200) {
                    resolve(xhr)
                } else {
                    reject(xhr)
                }
              
              }
            
        })
        const requestAnswer = await request;
        const payload = JSON.parse(requestAnswer.response);
        if (requestAnswer.status === 200) {
            return {ok: true, payload }
        } else {
            return {ok: false, payload: null, message: await requestAnswer.statusText}
        }
    },

    async putJson(path, obj) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        try {
            let req = await fetch(url.toString(), {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            if (req.ok) {
                return {ok: true, payload: await this.tryGetJson(req)}
            } else {
                console.log(req)
                return {ok: false, payload: null, error: req.err ? req.err : await req.text()}
            }
        } catch (e) {
            console.log(e)
            return {ok: false, error: e}
        }
    },

    async delete(path, querySpec) {
        if (path[0] !== '/') {
            path = '/' + path
        }
        const url = new URL(this.backendUrl + path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString()
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'DELETE', headers: this.token ? {
                    'Authorization': `Bearer ${this.token}`
                } : {}
            })
            if (req.ok) {
                return {ok: true, payload: null, status: req.status}
            } else {
                console.log(req)
                return {ok: false, payload: null, error: req.err, status: req.status}
            }
        } catch (e) {
            console.log(e)
            return {ok: false, error: e}
        }
    },

    async tryGetJson(req) {
        let result = null
        try {
            result = await req.json()
        } catch (e) {
            result = null
        }
        return result
    },

    clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName]
            }
        }
        return obj
    }
}

export default api
